import React from 'react';
import PropTypes from 'prop-types';
import 'styles/components/RouteItineraryInline';
import { Text } from '@reservamos/elements';

/**
 * RouteItineraryInline component.
 *
 * @param {object} props - departure, departureLabel, duration, durationLabel, arrival, arrivalLabel, showDuration.
 * @param {string} props.departure - The departure time.
 * @param {string} props.departureLabel - The label for the departure time.
 * @param {number|string} props.duration - The duration of the trip.
 * @param {string} props.durationLabel - The label for the trip duration.
 * @param {string} props.arrival - The arrival time.
 * @param {string} props.arrivalLabel - The label for the arrival time.
 * @param {boolean} props.showDuration - Flag to show the duration.
 */
const RouteItineraryInline = ({
  departure,
  departureLabel,
  duration,
  durationLabel,
  arrival,
  arrivalLabel,
  showDuration,
}) => {
  return (
    <div className="route-inline">
      <Text size="S" weight="bold">
        {departure}
      </Text>
      <Text size="XS">{departureLabel}</Text>
      {showDuration && (
        <>
          <Text size="XS" textAlign="center">
            {duration}
          </Text>
          <Text size="XS" textAlign="center">
            {durationLabel}
          </Text>
        </>
      )}
      <Text size="S" weight="bold" textAlign="right">
        {arrival}
      </Text>
      <Text size="XS" textAlign="right">
        {arrivalLabel}
      </Text>
    </div>
  );
};

RouteItineraryInline.propTypes = {
  departure: PropTypes.string.isRequired,
  departureLabel: PropTypes.string.isRequired,
  duration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  durationLabel: PropTypes.string,
  arrival: PropTypes.string.isRequired,
  arrivalLabel: PropTypes.string.isRequired,
  showDuration: PropTypes.bool,
};

RouteItineraryInline.defaultProps = {
  duration: null,
  durationLabel: null,
  showDuration: true,
};

export default RouteItineraryInline;
