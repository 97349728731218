import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@reservamos/elements';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import CSSVariablesProvider from './CSSVariablesProvider';

/**
 * Modal component with theme.
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the modal.
 * @param {React.ReactNode} props.footer - The footer content of the modal.
 * @param {boolean} props.removeContentPadding - Whether to remove content padding in the modal.
 * @param {boolean} props.hasBlushing - Whether the modal has a blushing effect.
 * @param {boolean} props.expandedModal - Whether the modal is expanded.
 * @param {function} props.onCloseModal - The function to call when the modal is closed.
 * @param {React.ReactNode} props.children - The content of the modal.
 * @param {React.ReactNode} props.responsiveSize - Select size of mobile modal
 * @returns {React.ReactNode} The rendered modal component.
 */
const ModalWithTheme = ({
  title,
  footer,
  removeContentPadding,
  hasBlushing,
  expandedModal,
  onCloseModal,
  children,
  responsiveSize,
}) => {
  const { theme, brand } = useWhitelabelEnvs();
  const { FUNNEL_STYLE } = useWhitelabelFeatures();

  return (
    <Modal
      footer={footer}
      removeContentPadding={removeContentPadding}
      title={title}
      hasBlushing={hasBlushing}
      expandedModal={expandedModal}
      onCloseModal={onCloseModal}
      responsiveSize={responsiveSize}
    >
      <CSSVariablesProvider
        theme={theme}
        funnelStyle={FUNNEL_STYLE}
        funnelStyleBrandVariation={brand.toUpperCase()}
      >
        {children}
      </CSSVariablesProvider>
    </Modal>
  );
};

ModalWithTheme.propTypes = {
  title: PropTypes.string,
  footer: PropTypes.node,
  removeContentPadding: PropTypes.bool,
  hasBlushing: PropTypes.bool,
  expandedModal: PropTypes.bool,
  onCloseModal: PropTypes.func,
  children: PropTypes.node,
  responsiveSize: PropTypes.string,
};

export default ModalWithTheme;
