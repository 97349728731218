import React, { useState } from 'react';
import Amenities from 'components/modal/Amenities';
import OverlayView from 'components/OverlayView';
import TripSummaryDetails from 'components/purchase/TripSummaryDetails';
import PropTypes from 'prop-types';

const propTypes = {
  departureTrip: PropTypes.object.isRequired,
  returnTrip: PropTypes.object.isRequired,
  roundTrip: PropTypes.bool.isRequired,
  isOpenTicket: PropTypes.bool.isRequired,
};

/**
 * TicketItinerary component.
 *
 * @param {object} props - departureTrip, returnTrip, roundTrip, isOpenTicket.
 * @param {object} props.departureTrip - Details of the departure trip
 * @param {object} props.returnTrip - Details of the return trip
 * @param {boolean} props.roundTrip - Flag indicating if it's a round trip
 * @param {boolean} props.isOpenTicket - Whether the ticket is open
 */
const TicketItinerary = ({ departureTrip, returnTrip, roundTrip, isOpenTicket }) => {
  const [amenities, setAmenities] = useState(null);

  /**
   * Show amenities modal.
   *
   * @param {React.Component} component - The component triggering the modal
   * @param {object} amenities - Amenities data to display
   */
  const showAmenities = (component, amenities) => setAmenities(amenities.amenities);

  /**
   * Close amenities modal.
   */
  const closeAmenities = () => setAmenities(null);

  return (
    <>
      <TripSummaryDetails
        departureTrip={departureTrip}
        returnTrip={returnTrip}
        roundTrip={roundTrip}
        showModal={showAmenities}
        showSeats={false}
        isOpenTicket={isOpenTicket}
      />
      <OverlayView title="amenities" visible={Boolean(amenities)} hideOverlay={closeAmenities}>
        {Boolean(amenities) && <Amenities amenities={amenities} hideModal={closeAmenities} />}
      </OverlayView>
    </>
  );
};

TicketItinerary.propTypes = propTypes;

export default TicketItinerary;
