import { range } from 'lodash';
import moment from 'moment';

/**
 * Check if the provided date is the current date (in other words, today).
 * @param {string} dateString - The date string to compare.
 * @returns {boolean} - True if the provided date is the current date, false otherwise.
 */
export function isCurrentDate(dateString) {
  const currentDate = moment().format('YYYY-MM-DD');
  return currentDate === dateString;
}

/**
 * Validate the date string to identify which date part is invalid.
 * The function checks if the provided date is not in the future by validating:
 * - Year must not be greater than current year
 * - If year is current year, month must not be greater than current month
 * - If year and month are current, day must not be greater than or equal to current day
 *
 * @param {string} year - The year.
 * @param {string} month - The month.
 * @param {string} day - The day.
 * @returns {Object} - The date validation object:
 *   @property {boolean} day - True if day is valid (not in future)
 *   @property {boolean} month - True if month is valid (not in future)
 *   @property {boolean} year - True if year is valid (not in future)
 * @example
 * validateDate('2024-03-19') // Past date
 * // => { day: true, month: true, year: true }
 *
 * validateDate('2024-03-21') // Future day
 * // => { day: false, month: true, year: true }
 *
 * validateDate('2024-04-19') // Future month
 * // => { day: true, month: false, year: true }
 */
export function validateDate(year, month, day) {
  const today = moment();
  const yearInt = year ? parseInt(year, 10) : null;
  const monthInt = month ? parseInt(month, 10) - 1 : null;
  const dayInt = day ? parseInt(day, 10) : null;
  const daysInMonth =
    monthInt != null ? moment({ year: yearInt, month: monthInt }).daysInMonth() : 31;
  const isDayInvalid =
    dayInt > daysInMonth ||
    (yearInt === today.year() && monthInt === today.month() && dayInt >= today.date());
  const isMonthInvalid = yearInt === today.year() && monthInt > today.month();
  const isYearInvalid = yearInt > today.year();
  return {
    day: !isDayInvalid,
    month: !isMonthInvalid,
    year: !isYearInvalid,
  };
}

/**
 * Get the days for a given year and month.
 *
 * If the month is not filled, return maximum days a month can have.
 * @param {string} year - The year.
 * @param {string} month - The month.
 * @returns {Array<{value: string, label: string}>} The days of the month.
 */
export function getDays(year, month) {
  const currentDate = moment();
  const currentYear = currentDate.year();
  const currentMonth = currentDate.month() + 1; // months are 0-indexed in moment
  const currentDay = currentDate.date();
  const yearToUse = !year.length ? currentYear - 1 : year;
  const daysInMonth = !month ? 31 : moment(`${yearToUse}-${month}`, 'YYYY-MM').daysInMonth();

  return range(1, daysInMonth + 1)
    .filter((day) => {
      if (parseInt(year, 10) === currentYear && parseInt(month, 10) === currentMonth) {
        return day < currentDay;
      }
      return true;
    })
    .map((day) => {
      const dayNumber = day.toString().padStart(2, '0');
      return {
        value: dayNumber,
        label: dayNumber,
      };
    });
}

/**
 * Get the months for a given year.
 *
 * If the year is not filled, use the year before the current year,
 * in order make sure any month is added as an option.
 * @param {string} year - The year.
 * @returns {Array<{value: string, label: string}>} The months of the year.
 */
export function getMonths(year) {
  const currentYear = moment().year();
  const currentMonth = moment().month() + 1; // months are 0-indexed in moment

  const baseYear = !year ? currentYear - 1 : year;

  const months = moment.monthsShort();
  const filteredMonths = baseYear < currentYear ? months : months.slice(0, currentMonth);

  return filteredMonths.map((month, index) => {
    const monthNumber = (index + 1).toString().padStart(2, '0');

    return {
      value: monthNumber,
      label: `${monthNumber} - ${month}`,
    };
  });
}

/**
 * Get the years based on passenger type.
 * @param {string} passengerType - The type of passenger (adult, child, infant).
 * @returns {Array<{value: number, label: number}>} The years.
 */
export function getYears(passengerType, year = moment().year()) {
  const passengerAgeRanges = { adult: 100, child: 19, infant: 2 };
  const limitYear = year - passengerAgeRanges[passengerType] - 1;

  const years = range(year, limitYear, -1);

  return years.map((year) => ({ value: year, label: year }));
}

export default { isCurrentDate };
