import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FieldGroup, Select, Spacing } from '@reservamos/elements';
import { getDays, getMonths, getYears, validateDate } from 'utils/date';

/**
 * BirthDateField component.
 * @param {Object} props - The component props.
 * @param {string} props.passengerType - The type of passenger (adult, child, infant).
 * @param {Object} props.input - The input props from redux-form.
 * @param {Object} props.meta - The meta props from redux-form.
 * @param {boolean} props.isDisabled - Whether the field is disabled.
 * @returns {JSX.Element} The rendered component.
 */
const BirthDateField = ({ passengerType, input, meta, isDisabled }) => {
  const { t } = useTranslation('passengers');
  const [year = '', month = '', day = ''] = input.value.split('-');
  const [dateValidation, setDateValidation] = useState(() => validateDate(year, month, day));
  const isDayInvalid = !dateValidation.day || (meta.touched && !day);
  const isMonthInvalid = !dateValidation.month || (meta.touched && !month);
  const isYearInvalid = !dateValidation.year || (meta.touched && !year);

  /**
   * Handle date change.
   * @param {string} type - The type of date part (day, month, year).
   * @param {string} event - The event.
   */
  function handleChange(type, event) {
    const newValue = event.target.value;
    const newYear = type === 'year' ? newValue : year;
    const newMonth = type === 'month' ? newValue : month;
    const newDay = type === 'day' ? newValue : day;
    const newDateValidation = validateDate(newYear, newMonth, newDay);
    setDateValidation(newDateValidation);
    const newDate = [
      newDateValidation.year ? newYear : '',
      newDateValidation.month ? newMonth : '',
      newDateValidation.day ? newDay : '',
    ].join('-');
    input.onChange(newDate);
  }

  return (
    <div className="birth-date-field">
      <FieldGroup label={t('birth_date')}>
        <Spacing isResponsive={false} size="XS" flexGrow>
          <Select
            id="day"
            placeholder={t('day')}
            value={day}
            hasError={isDayInvalid}
            errorMessage={t('errors.day')}
            options={getDays(year, month)}
            onChange={(event) => handleChange('day', event)}
            isDisabled={isDisabled}
          />
          <Select
            id="month"
            placeholder={t('month')}
            value={month}
            hasError={isMonthInvalid}
            errorMessage={t('errors.month')}
            options={getMonths(year)}
            onChange={(event) => handleChange('month', event)}
            isDisabled={isDisabled}
          />
          <Select
            id="year"
            placeholder={t('year')}
            value={year}
            hasError={isYearInvalid}
            errorMessage={t('errors.year')}
            options={getYears(passengerType)}
            onChange={(event) => handleChange('year', event)}
            isDisabled={isDisabled}
          />
        </Spacing>
      </FieldGroup>

      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Field {...input} type="hidden" component="input" />
    </div>
  );
};

BirthDateField.propTypes = {
  passengerType: PropTypes.oneOf(['adult', 'child', 'infant']).isRequired,
  // Redux-form props
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
};

export default BirthDateField;
