import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Currency, Icon, Spacing, Text } from '@reservamos/elements';
import { getCurrencyDecimals, getCurrencyPrefix, getCurrencySuffix } from 'utils/currency';

/**
 * Component for displaying breakdown pricing details.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.label - The label for the pricing (e.g., passenger type or category).
 * @param {string} props.price - The price to display (formatted as a string).
 * @param {string} [props.discount] - The primary discount percentage or value (optional).
 * @param {string} [props.subDiscount] - A secondary discount percentage or value (optional).
 * @param {boolean} [props.hasDiscount=false] - Indicates if there is a discount applied.
 * @param {boolean} [props.showDiscountBadge=false] - Shows the discount badge if true.
 * @param {string} [props.discountIcon] - Icon to indicate a discount (optional).
 * @param {string} [props.typeIcon] - Icon type representing the passenger or item type (optional).
 *
 * @returns {JSX.Element} The rendered breakdown pricing component.
 */
const BreakdownPricing = ({
  label,
  price,
  discount,
  subDiscount,
  hasDiscount,
  showDiscountBadge,
  discountIcon,
  typeIcon,
}) => {
  const sign = getCurrencyPrefix();
  return (
    <Spacing justifyContent="space-between" alignItems="center">
      <Spacing size="XS" alignItems="center">
        {subDiscount ? <div style={{ width: '15px' }} /> : <Icon type={typeIcon} size="S" />}

        <Text
          size="S"
          color={subDiscount ? 'grayMedium' : 'primary'}
          weight={subDiscount ? 'regular' : 'semibold'}
        >
          {label}
        </Text>

        {showDiscountBadge && (subDiscount || discount) && (
          <Badge smallPadding roundCorners type="gradient">
            <Text color="white" size="XS">
              {subDiscount || discount}
            </Text>
          </Badge>
        )}
      </Spacing>

      <Spacing alignItems="center" size="XS">
        {discountIcon ? <Icon size="S" type={discountIcon} /> : null}

        <Currency
          size="S"
          color="grayMedium"
          weight={subDiscount ? 'regular' : 'bold'}
          price={price}
          currency={getCurrencySuffix()}
          decimals={getCurrencyDecimals()}
          sign={hasDiscount ? `-${sign}` : sign}
        />
      </Spacing>
    </Spacing>
  );
};

BreakdownPricing.propTypes = {
  label: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  discount: PropTypes.string,
  subDiscount: PropTypes.string,
  hasDiscount: PropTypes.bool,
  showDiscountBadge: PropTypes.bool,
  discountIcon: PropTypes.string,
  typeIcon: PropTypes.bool,
};

BreakdownPricing.defaultProps = {
  discount: '',
  subDiscount: '',
  hasDiscount: false,
  showDiscountBadge: false,
  discountIcon: '',
  typeIcon: false,
};

export default BreakdownPricing;
