import React from 'react';
import { Box, Gap, Icon, SmallButton, Spacing, Text, ProviderLogo } from '@reservamos/elements';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './DepartureTripPicked.scss';
import AnimationOverview from '../../atoms/AnimationOverview';

/**
React functional component that represents a selected departure trip
  @param {object} props - The component's props
  @param {string} props.originCity - The origin city of the trip
  @param {string} props.destinationCity - The destination city of the trip
  @param {string} props.departureDate - The departure date of the trip (optional)
  @param {string} props.departureTime - The departure time of the trip (optional)
  @param {string} props.departureDuration - The duration of the trip
  @param {string} props.departureLineLogo - The logo of the departure line
  @param {boolean} props.isOpenTicket - Whether the ticket is open or not
  @param {function} props.onClick - The function to be called when the user clicks the button to redirect to the detailed view of the selected trip
  @returns {JSX.Element} - The DepartureTripPicked component
*/
const DepartureTripPickedFlat = ({
  originCity,
  destinationCity,
  departureDate,
  departureTime,
  onClick,
  departureDuration,
  departureLineLogo,
  isOpenTicket,
  showAnimation,
}) => {
  const { t } = useTranslation('trips');

  const contentTypes = [
    <ProviderLogo name="lineName" imgSrc={departureLineLogo} isSmall />,
    <Gap alignItems="center">
      <Text
        mobileSize="S"
        maxWidthEllipsis={120}
        mobileMaxWidthEllipsis={100}
        textTransform="capitalize"
      >
        {originCity}
      </Text>
      -
      <Text
        mobileSize="S"
        maxWidthEllipsis={120}
        mobileMaxWidthEllipsis={100}
        textTransform="capitalize"
      >
        {destinationCity}
      </Text>
    </Gap>,
  ];

  return (
    <div className="box-overview">
      <div className="departure-trip departure-trip-flat">
        <div className={isOpenTicket ? 'departure-open-ticket-header' : 'departure-trip-header'}>
          <Box paddingHorizontal="S" paddingVertical="S" hasShadow borderRadius="M">
            <Spacing
              size="M"
              mobileSize="XS"
              justifyContent="space-between"
              alignItems="center"
              fullWidth
            >
              <Gap alignItems="center" columnGap="S" responsiveColumnGap="XS">
                <Icon type="Success" color="success" mobileSize="S" />
                <Text mobileSize="M" size="L" weight="bold">
                  {t('trips:selected_trip')}
                </Text>

                {isOpenTicket && (
                  <Text mobileSize="S" size="L" weight="bold" color="grayMedium">
                    {t('trips:open_ticket_trip')}
                  </Text>
                )}
              </Gap>

              <SmallButton
                onClick={onClick}
                color="accent"
                isRounded
                text={t('trips:redirect_selected_trip')}
              />
            </Spacing>
          </Box>
        </div>

        {!isOpenTicket && (
          <div className="departure-trip-bottom">
            <Spacing justifyContent="space-between" alignItems="center">
              <Text weight="semibold" italic textTransform="capitalize">
                {departureDate}
              </Text>

              <Text weight="semibold" italic>
                {departureTime}
              </Text>
            </Spacing>
            <div className="bottom-content">
              {showAnimation && <AnimationOverview contentTypes={contentTypes} />}
              <Text>{departureDuration}</Text>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

DepartureTripPickedFlat.propTypes = {
  originCity: PropTypes.string.isRequired,
  destinationCity: PropTypes.string.isRequired,
  departureDate: PropTypes.string.isRequired,
  departureTime: PropTypes.string.isRequired,
  departureDuration: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  departureLineLogo: PropTypes.string.isRequired,
  isOpenTicket: PropTypes.bool.isRequired,
  showAnimation: PropTypes.bool.isRequired,
};

export default DepartureTripPickedFlat;
