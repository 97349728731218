import { getDoc2BrandMap, getIgnoredDocuments } from 'utils/documentMaps';
import store from '@/store';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { decodePassengers } from 'utils/Reserbus';
import { times } from 'lodash';
import { getCountryCallingCode } from 'react-phone-number-input';
import { getStoredPassengers } from './storedPassengers';
import { getUserByWalletType } from './loyalty';
import { isHybridTrip } from './wayIsOpenTicket';
import normalizePhone from './normalizePhone';

const DEFAULT_CATEGORY = 'general';

// Order of passengers selection
const orderedPassengerCategories = [
  'general',
  'adult',
  'older',
  'special',
  'teacher',
  'student',
  'wheelchair_handicap',
  'minor',
  'pet_friendly',
];

/**
 * Removes adjacent seats from the provided array of seats.
 * @param {Array} seats - The array of seats.
 * @returns {Array} filteredSeats - The filtered array of seats without adjacent seats.
 */
const removeAdjacentSeats = (seats) => {
  return seats.filter((seat) => !seat.isPickedAsAdjacent);
};

/**
 * Returns the countable categories based on the provided categories.
 * @param {Object} params - The parameters object.
 * @param {Object|String} params.categories - The categories object or string to be decode.
 * @returns {Array} countableCategories - The countable categories.
 */
export const getCountableCategories = ({ categories }) => {
  const decodedPassengers =
    typeof categories === 'string' ? decodePassengers(categories) : categories;
  const countableCategories = [];
  // Based on the order of passengers the array for assignment is made
  orderedPassengerCategories.forEach((category) => {
    const passengerCategory = decodedPassengers[category];
    if (passengerCategory)
      countableCategories.push(...times(decodedPassengers[category], () => category));
  });
  return countableCategories;
};

/**
 * Returns the Document options for the passengers in the current brand
 * @returns {Object} passengersDocumentsOptions - The document options for the passengers
 */
const getDefaultPassengersDocuments = () => {
  const doc2BrandMapPassengers = getDoc2BrandMap('passengers');
  const doc2BrandMapPassengersSecond = getDoc2BrandMap('passengersSecond');
  const passengersDocumentsOptions = Object.values(doc2BrandMapPassengers);
  const passengersDocumentsSecondOptions = Object.values(doc2BrandMapPassengersSecond);
  const passengersDocumentsOptionsKeys = Object.keys(doc2BrandMapPassengers);

  const passengersDocumentsToIgnore = getIgnoredDocuments();
  const availableDocumentsForFirstPassengerKeys = passengersDocumentsOptionsKeys.filter(
    (option) => !passengersDocumentsToIgnore.includes(option),
  );
  const availableDocumentsForFirstPassenger = Object.entries(doc2BrandMapPassengers)
    .filter(([key]) => availableDocumentsForFirstPassengerKeys.includes(key))
    // eslint-disable-next-line no-unused-vars
    .map(([_, value]) => value);

  return {
    passengersDocumentsOptions,
    passengersDocumentsSecondOptions,
    availableDocumentsForFirstPassenger,
  };
};

/**
 * Returns the passenger category available
 * @param {Object} params - Method params
 * @param {String} params.passengerBusCategory - The passenger category
 * @param {Array} params.busCategories - The bus categories available
 * @param {Number} params.index - The index of the passenger
 * @returns {String} category - The default passenger category
 */
export const getPassengerCategory = ({ passengerBusCategory, busCategories, index }) => {
  // The first passenger can't be a minor because they cannot travel alone
  const isMinorAlone = passengerBusCategory === 'minor' && index === 0;
  if (!busCategories || isMinorAlone) return DEFAULT_CATEGORY;

  const category = busCategories.find((item) => {
    return item.type === passengerBusCategory;
  });
  return category?.type || DEFAULT_CATEGORY;
};

/**
 * Returns the default passenger data
 * @param {Object} params - The params object
 * @param {Boolean} params.isFirstPassenger - The flag to check if the passenger is the first one
 * @param {Object} params.passenger - The passenger data available
 * @param {String} params.seat - The seat of the passenger
 * @returns {Object} passenger - The default passenger data
 */
const createPassenger = ({ isFirstPassenger, passenger, seat }) => {
  const {
    whitelabelConfig: { env },
  } = store.getState();
  const {
    availableDocumentsForFirstPassenger,
    passengersDocumentsOptions,
    passengersDocumentsSecondOptions,
  } = getDefaultPassengersDocuments();

  const passengerCamelized = passenger && camelizeKeys(passenger);
  const phoneCountry = passengerCamelized?.phoneCountry || env.lang?.default?.substring(3) || '';
  const phoneCode = getCountryCallingCode(phoneCountry) || '';
  let phone = passengerCamelized?.phone || '';
  if (passengerCamelized?.phoneCountry) {
    phone = normalizePhone(phone, phoneCountry);
  }

  let defaultDocumentType;
  if (env.brand === 'cds') {
    defaultDocumentType = 'DNI';
  } else if (isFirstPassenger) {
    defaultDocumentType = availableDocumentsForFirstPassenger[0] || 'CC';
  } else {
    defaultDocumentType = passengersDocumentsOptions[0] || 'CC';
  }

  return {
    id: passengerCamelized?.id || '',
    firstName: passengerCamelized?.firstName || '',
    secondFirstName: passengerCamelized?.secondFirstName || '',
    lastName: passengerCamelized?.lastName || '',
    secondLastName: passengerCamelized?.secondLastName || '',
    email: passengerCamelized?.email || '',
    phone,
    phoneCountry,
    phoneCode,
    name:
      (passengerCamelized?.firstName &&
        `${passengerCamelized?.firstName} ${passengerCamelized?.lastName}`.trim()) ||
      '',
    category: passengerCamelized?.category || 'adult',
    busCategory: passengerCamelized?.busCategory || DEFAULT_CATEGORY,
    documentType: passengerCamelized?.documentType || defaultDocumentType,
    documentTypeSecond:
      passengerCamelized?.documentTypeSecond || passengersDocumentsSecondOptions[0],
    wantsOutgoingInsurance: passengerCamelized?.wantsOutgoingInsurance || false,
    wantsIncomingInsurance: passengerCamelized?.wantsIncomingInsurance || false,
    nationality: passengerCamelized?.nationality || passengerCamelized?.isoCountryCode,
    dateOfBirth: passengerCamelized?.dateOfBirth || '',
    document: passengerCamelized?.document || '',
    documentId: passengerCamelized?.documentId || '',
    documentIdSecond: passengerCamelized?.documentIdSecond || '',
    gender: passengerCamelized?.gender || '',
    minorTravelMode: passengerCamelized?.minorTravelMode || '',
    ...(seat && seat),
  };
};

/**
 * Returns the selected seat data by passenger usable for the UI
 * @param {Object} params - The params object
 * @param {Object} params.passengers - The passengers
 * @param {Array} params.departSeats - The departure seats
 * @param {Array} params.returnSeats - The return seats
 * @returns
 */
export const getSelectedSeatForUI = ({ passengers, departSeats, returnSeats }) => {
  return passengers.map((passenger, index) => {
    const departAssignedSeat = departSeats && departSeats[index];
    const returnAssignedSeat = returnSeats && returnSeats[index];

    return {
      ...passenger,
      seats: {
        ...(departAssignedSeat && { departure: departAssignedSeat }),
        ...(returnAssignedSeat && { return: returnAssignedSeat }),
      },
    };
  });
};

/**
 * Returns the priority passenger data
 * @param {Object} params - The params object
 * @param {Object} params.hasSeats - The way has seats selected
 * @param {Number} params.seatsNeeded - The seats needed
 * @param {Number} params.searchedPassengers - Passengers categories used for the search
 * @returns {Object} passenger - The priority passenger data
 */
const getPriorityPassengerData = ({ hasSeats, seatsNeeded, searchedPassengers }) => {
  const { purchase } = store.getState();
  const purchaseJS = purchase?.toJS();
  const { passengers = [], walletType, departs, returns, isExchange, busCategories } = purchaseJS;

  /**
   * Is an hybrid trip (open and normal ticket) and there is not seats selected yet, it is return an
   * empty array because the next way is the one that has to fill the default passengers
   */
  if (isHybridTrip(departs, returns) && !hasSeats) return [];

  const storedPassengers = getStoredPassengers();

  const purchasePassengers = passengers?.length && passengers;
  let storedPassengersValidated = storedPassengers?.length && storedPassengers;

  // If the way doesn't have seats, the seats saved in local storage are removed
  if (!hasSeats && storedPassengersValidated) {
    storedPassengersValidated.forEach((passenger) => delete passenger.seats);
    storedPassengersValidated = storedPassengersValidated?.slice(0, 1);
  }

  const priorityPassengers = purchasePassengers || storedPassengersValidated || [];

  // If a user is logged in, it is parsed and used as the first passenger
  const loggedUser = getUserByWalletType(walletType);
  if (!isExchange && loggedUser) {
    const parsedLoggedUser = createPassenger({
      isFirstPassenger: true,
      passenger: purchasePassengers
        ? {
            ...loggedUser,
            id: purchasePassengers[0].id,
            category: priorityPassengers[0].category,
            busCategory: priorityPassengers[0].busCategory,
          }
        : loggedUser,
    });
    priorityPassengers[0] = parsedLoggedUser;
  }

  const countableCategories =
    searchedPassengers &&
    getCountableCategories({ categories: decamelizeKeys(searchedPassengers) });

  const passengersData = priorityPassengers.length ? priorityPassengers : [{}];
  const seatsQuantity = hasSeats && seatsNeeded.length;

  if (
    (hasSeats && passengersData.length < seatsQuantity) ||
    passengersData.length < countableCategories?.length
  ) {
    const timesNumber =
      hasSeats && seatsQuantity
        ? seatsQuantity - passengersData.length
        : countableCategories.length - passengersData.length;
    times(timesNumber, () => passengersData.push({}));
  }

  passengersData.forEach((passenger, index) => {
    // Checks if the passenger from the purchase already has a category to used or used the one from the selection
    let passengerBusCategory = passenger?.busCategory;

    if (countableCategories) {
      let searchedCategory = countableCategories[index];

      // If the category is pet, it is checked if pet seats are selected
      if (hasSeats && searchedCategory === 'pet_friendly') {
        searchedCategory = DEFAULT_CATEGORY;
      }

      passengerBusCategory = passengerBusCategory || searchedCategory;
    }

    passenger.busCategory = getPassengerCategory({
      passengerBusCategory,
      busCategories,
      index,
    });
  });

  return passengersData;
};

/**
 * Adds departureSeats or returnSeats to each passenger, according to the way property.
 *
 * @returns {Array} A new instance of the passengers array with the departureSeats or returnSeats added
 *
 * @param {Object} params - An object with the follogin properties:
 * @param {String} params.way - The way of the trip (departure or return)
 * @param {Array} params.passengers - The passengers to add the seats
 * @param {Array} params.trips - The trips of the way
 * @param {Array} params.seats - The seats to assign to the passengers. Each seat should have a tripSlug property
 *
 * The departureSeats or returnSeats property will be added to each passenger, with the following structure:
 *
 * ```js
 * passenger.departureSeats = {
 *   "tripSlug1": seat,
 *   "tripSlug2": seat
 * }
 *
 * passenger.returnSeats = {
 *   "tripSlug1": seat,
 *   "tripSlug2": seat
 * }
 * ```
 */
const addWaySeatsPerPassenger = ({ way, passengers, trips, seats }) => {
  /**
   * seatsByPassenger has the following structure:
   *
   * ```js
   * [
   *   [0]: {
   *     [tripSlug1]: seat
   *     [tripSlug2]: seat
   *   },
   *   [1]: {
   *     [tripSlug1]: seat
   *     [tripSlug2]: seat
   *   }
   * ]
   * ```
   */
  const seatsByPassenger = passengers.reduce((acc, passenger, passengerIndex) => {
    const passengerSeatsByTrip = trips.reduce((acc, trip) => {
      const tripSeats = seats.filter((seat) => seat.tripSlug === trip.trip.id);
      return {
        ...acc,
        [trip.trip.id]: tripSeats[passengerIndex],
      };
    }, {});
    return {
      ...acc,
      [passengerIndex]: passengerSeatsByTrip,
    };
  }, []);
  return passengers.map((passenger, index) => {
    return {
      ...passenger,
      ...(way === 'departure' ? { departureSeats: seatsByPassenger[index] } : {}),
      ...(way === 'return' ? { returnSeats: seatsByPassenger[index] } : {}),
    };
  });
};

/**
 * Returns the initial passengers data
 * @returns {Array} passengers - The initial passengers data
 */
export const getInitialPassengers = ({
  allowsSeatSelection,
  departureSelectedSeats = [],
  returnSelectedSeats = [],
  passengerSelection,
  departureTrips = [],
  returnTrips = [],
}) => {
  const cleanedDepartureSeats = removeAdjacentSeats(departureSelectedSeats);
  const cleanedReturnSeats = removeAdjacentSeats(returnSelectedSeats);

  // Number of passengers for the purchase ---------------
  const departureTripsLength = departureTrips.length || 1;
  /**
   * Since trips can have connections. The general formula to calculate the number of passengers is:
   * - Number of seats selected / Number of trips
   *
   * This works for both departure and return trips. And also for direct trips or trips with connections.
   */
  let selectedSeatsNeeded = Array(cleanedDepartureSeats.length / departureTripsLength).fill({});

  // If the departure seats are not present, the return seats are used to create the default passengers data
  if (!selectedSeatsNeeded?.length && cleanedReturnSeats?.length) {
    const returnTripsLength = returnTrips.length || 1;
    selectedSeatsNeeded = Array(cleanedReturnSeats.length / returnTripsLength).fill({});
  }
  // Number of passengers for the purchase ---------------

  // The passengers to prefill are got based on the data priority
  const hasSeats = allowsSeatSelection && selectedSeatsNeeded.length;

  // get passengers from purchase or local storage.
  // If there are no passengers, an empty array is returned
  const passengersToUse = getPriorityPassengerData({
    hasSeats,
    seatsNeeded: selectedSeatsNeeded,
    searchedPassengers: passengerSelection,
  });
  let formattedPassengers;

  if (hasSeats) {
    // If it has seats, the passengers data is created with the seats selected
    formattedPassengers = selectedSeatsNeeded.map((item, index) => {
      return createPassenger({
        isFirstPassenger: index === 0,
        passenger: passengersToUse[index],
      });
    });

    formattedPassengers = getSelectedSeatForUI({
      passengers: formattedPassengers,
      departSeats: cleanedDepartureSeats,
      returnSeats: cleanedReturnSeats,
    });

    const departureHasConnections = departureTrips.length > 1;
    if (departureHasConnections) {
      formattedPassengers = addWaySeatsPerPassenger({
        way: 'departure',
        passengers: formattedPassengers,
        trips: departureTrips,
        seats: cleanedDepartureSeats,
      });
    }

    const returnHasConnections = returnTrips.length > 1;
    if (returnHasConnections) {
      formattedPassengers = addWaySeatsPerPassenger({
        way: 'return',
        passengers: formattedPassengers,
        trips: returnTrips,
        seats: cleanedReturnSeats,
      });
    }
  } else {
    formattedPassengers = passengersToUse.map((passenger, index) => {
      return createPassenger({
        isFirstPassenger: index === 0,
        passenger,
      });
    });
  }

  return formattedPassengers;
};

/**
 * Returns the list of passengers that are not available in the trips.
 * @param {Object} options - The options object.
 * @param {Object} options.neededPassengers - The needed passengers.
 * @param {Array} options.trips - The trips.
 * @returns {Array} - The list of passengers that are not available in the trips.
 */
export const passengersNotAvailable = ({ neededPassengers, trips }) => {
  /**
   * If some passenger needed is not found in the trips, this passengers is saved to notify
   * that the passengers was not found in this route
   */
  return (
    Object.keys(neededPassengers)?.filter(
      (passenger) =>
        !trips.some((trip) => {
          // If adult is include it means it is the old version so this is not needed
          if (passenger === 'adult') return true;
          return (
            !trip.openTicket &&
            trip.passengerTypes.some((type) => type.type === passenger && type.availability)
          );
        }),
    ) || []
  );
};

/**
 * Checks if the searched passengers can be filtered.
 * If the searched passengers are more than one type or is general but more than one, it can be filtered.
 * @param {Object} params - The parameters object.
 * @param {Object} params.searchPassengers - The searched passengers.
 * @returns {boolean} - True if the searched passengers can be filtered, false otherwise.
 */
export const searchedPassengersCanBeFiltered = ({ searchPassengers }) =>
  searchPassengers && !(Object.keys(searchPassengers).length === 1 && searchPassengers.general);
