import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Text } from '@reservamos/elements';
import useWhitelabelCopies from 'hooks/whitelabel/useWhitelabelCopies';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import PassengerPolicies from './purchase/PassengerPolicies';
import CheckboxRenderer from './CheckboxRenderer';

function AgreementCheckboxFields() {
  const { t } = useTranslation('purchase');
  const { provider } = useWhitelabelCopies();
  const { SHOW_PROMOTIONAL_TERMS_CHECKBOX, SHOW_LEGAL_TERMS_CHECKBOX } = useWhitelabelFeatures();

  return (
    <form>
      {SHOW_LEGAL_TERMS_CHECKBOX && (
        <Field
          name="legalTerms"
          id="legalTermsCheckbox"
          type="checkbox"
          label={<PassengerPolicies isCheckbox />}
          component={CheckboxRenderer}
        />
      )}
      {SHOW_PROMOTIONAL_TERMS_CHECKBOX && (
        <Field
          name="promotionalTerms"
          id="promotionalTermsCheckbox"
          type="checkbox"
          label={<Text size="S">{t('text.promotional_terms', { provider: provider.name })}</Text>}
          component={CheckboxRenderer}
        />
      )}
    </form>
  );
}

export default reduxForm({
  form: 'purchaser',
})(AgreementCheckboxFields);
