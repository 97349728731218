import 'styles/components/TripItinerary/TripItinerary';
import React from 'react';
import PropTypes from 'prop-types';
import TimeLine from 'components/TimeLine';
import DetailsFooter from './DetailsFooter';
import DurationHeader from '../../ui/atoms/DurationHeader';

const propTypes = {
  details: PropTypes.shape({
    tripDuration: PropTypes.string,
    departureDate: PropTypes.string,
    arrivalDate: PropTypes.string,
    footer: PropTypes.shape({
      logo: PropTypes.string.isRequired,
      service: PropTypes.string.isRequired,
      amenities: PropTypes.array.isRequired,
    }).isRequired,
    route: PropTypes.array,
  }),
};

/**
 * TripDetails component.
 *
 * @param {object} props - details.
 * @param {object} props.details - Object containing trip details.
 */
const TripDetails = ({ details }) => {
  if (!details) return null;
  const { tripDuration, departureDate, arrivalDate, route, footer } = details;
  return (
    <div className="detail-trip">
      <DurationHeader
        duration={tripDuration}
        departureMoment={departureDate}
        arrivalMoment={arrivalDate}
      />

      <div className="route-detail">
        <TimeLine route={route} />
      </div>

      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <DetailsFooter {...footer} />
    </div>
  );
};
TripDetails.propTypes = propTypes;

export default TripDetails;
