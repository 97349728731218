import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Gap, Spacing, Text } from '@reservamos/elements';
import ProvidersGrid from '../../ui/layouts/ProvidersGrid';
import Amenity from '../../ui/atoms/Amenity';
import 'styles/components/AmenitiesTooltip';
import { serviceName as service } from '../../utils/Reserbus';

const propTypes = {
  provider: PropTypes.shape({
    transportType: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    ally: PropTypes.bool.isRequired,
    copyrightProtected: PropTypes.bool,
    logoUrl: PropTypes.string.isRequired,
    siteUrl: PropTypes.string,
    averageRating: PropTypes.number,
    serviceName: PropTypes.string,
    openTicketLogosUrls: PropTypes.array.isRequired,
  }).isRequired,
  amenities: PropTypes.array.isRequired,
  isOpenTicket: PropTypes.bool.isRequired,
};

const ProviderAmenities = ({ provider, amenities, isOpenTicket }) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  if (!amenities.length) return null;

  const { name, logoUrl, serviceName, openTicketLogosUrls } = provider;

  const logosToShow =
    isOpenTicket &&
    openTicketLogosUrls &&
    Array.isArray(openTicketLogosUrls) &&
    openTicketLogosUrls.length > 0
      ? openTicketLogosUrls
      : [{ name, url: logoUrl }];

  return (
    <Spacing vertical size="S">
      <Gap>
        <ProvidersGrid logosToShow={logosToShow} />

        {features.SHOW_SERVICE_TYPE && <Text size="M">{service(serviceName)}</Text>}
      </Gap>
      <Spacing vertical size="S">
        {amenities.map((amenity) => (
          <Amenity type={amenity} key={amenity} />
        ))}
      </Spacing>
    </Spacing>
  );
};

ProviderAmenities.propTypes = propTypes;

export default ProviderAmenities;
