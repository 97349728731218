import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Spacing, Text } from '@reservamos/elements';
import ProviderAmenities from './ProviderAmenities';
import 'styles/components/TripItinerary/ProviderDetails';

const propTypes = {
  amenities: PropTypes.array.isRequired,
  showModal: PropTypes.func.isRequired,
  isOpenTicket: PropTypes.bool.isRequired,
};

/**
 * ProviderDetails component.
 *
 * @param {object} props - amenities, showModal, isOpenTicket.
 * @param {array} props.amenities - The list of amenities.
 * @param {function} props.showModal - Function to show modal.
 * @param {boolean} props.isOpenTicket - Flag indicating if ticket is open.
 */
const ProviderDetails = ({ amenities, showModal, isOpenTicket }) => {
  const { t } = useTranslation('general');
  if (!amenities.some(({ services }) => services && services.length)) return null;

  return (
    <Spacing vertical>
      <Text size="S" weight="bold">
        {t('amenities_and_services')}
      </Text>

      {amenities.map(({ provider, services }, index) => {
        const amenityKey = `${provider.id}-${index}`;

        return (
          <ProviderAmenities
            key={amenityKey}
            provider={provider}
            amenities={services}
            showModal={showModal}
            isOpenTicket={isOpenTicket}
          />
        );
      })}
    </Spacing>
  );
};

ProviderDetails.propTypes = propTypes;

export default ProviderDetails;
