import React from 'react';
import PropTypes from 'prop-types';
import { formatDuration } from 'utils/Helpers';
import moment from 'moment';
import { Text, Spacing, Box } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';

/**
 * DurationHeader component.
 *
 * @param {Object} props - Component props
 * @param {object} props.departureMoment - Moment object for the departure time
 * @param {number} props.duration - Duration of the trip in minutes
 * @param {object} props.arrivalMoment - Moment object for the arrival time
 */
const DurationHeader = ({ departureMoment, duration, arrivalMoment }) => {
  const { t } = useTranslation('trips');
  const departureDate = departureMoment.format('DD MMM YY');
  const arrivalDate = arrivalMoment.format('DD MMM YY');
  const tripDuration = formatDuration(moment.duration(duration, 'm'));
  return (
    <Box paddingVertical="S" border="bottom" borderColor="grayBorder">
      <Spacing size="S" justifyContent="space-between">
        <Text size="S">
          {t('leaves')}
          <b>{departureDate}</b>
        </Text>
        <Text size="S" weight="bold">
          {tripDuration}
        </Text>
        <Text size="S">
          {t('arrival')}
          <b>{arrivalDate}</b>
        </Text>
      </Spacing>
    </Box>
  );
};

DurationHeader.propTypes = {
  departureMoment: PropTypes.object.isRequired,
  arrivalMoment: PropTypes.object.isRequired,
  duration: PropTypes.number.isRequired,
};

export default DurationHeader;
