import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './BreadcrumbItem.scss';
import { Icon } from '@reservamos/elements';

const BreadcrumbItem = ({ title, label, icon, onClick, isActive }) => {
  const { features, env } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('purchase');
  const SHOW_ON_CLASSIC = features.FUNNEL_STYLE === 'CLASSIC';

  const {
    theme: { breadcrumbsIcons },
  } = env;

  const iconShowed = breadcrumbsIcons ? breadcrumbsIcons[icon.toLowerCase()] : icon;

  return (
    <div
      className={`rvui-breadcrumb ${SHOW_ON_CLASSIC && 'rvui-breadcrumb-classic'} ${
        isActive ? 'rvui-breadcrumb-active' : ''
      }`}
      onClick={onClick}
    >
      <Icon type={iconShowed} hasMargin marginSide="right" margin="10" color="accent" />
      <h4 className={isActive ? 'rvui-breadcrumb-title-active' : 'rvui-breadcrumb-title'}>
        {title}
        {Boolean(label) && ` ${t(`label.${label}`)}`}
      </h4>
    </div>
  );
};

const propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};

const defaultProps = {
  onClick: () => {},
  isActive: false,
  label: '',
};

BreadcrumbItem.defaultProps = defaultProps;
BreadcrumbItem.propTypes = propTypes;

export default BreadcrumbItem;
