import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Text, Box, Spacing } from '@reservamos/elements';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import { useTranslation } from 'react-i18next';

const propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  time: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      time: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const StopItem = ({ title, description, time }) => {
  const { t } = useTranslation('trips');
  const timeSegments = time.map(({ title, time, nextDayBadge }, index) => {
    const key = `${time}-${index}`;
    return (
      <div key={key}>
        <Text size="S">{title}</Text>
        <Text size="S">{time}</Text>
        {nextDayBadge ? (
          <Badge type="accent" roundCorners>
            <Text color="white" size="S" weight="semibold" italic elementType="span">
              {t('next_day')}
            </Text>
          </Badge>
        ) : null}
      </div>
    );
  });

  const features = useWhitelabelFeatures();
  const showTerminal = features.SHOW_TERMINAL_NAME_AT_ITINERARY_ITEM;
  return (
    <div className="route-detail-row stopover">
      <div className="rd-left">{timeSegments}</div>

      <div className="rd-center">
        <i className="ic-stopover" />
      </div>

      <div className="rd-right bus">
        <Box
          paddingVertical="S"
          paddingHorizontal="M"
          borderRadius="S"
          border="all"
          borderColor="grayBorder"
          alphaBg="M"
          bgColor="grayBorder"
          hasWidth
        >
          <Spacing size="XS" vertical>
            <Text weight="bold" size="S">
              {title}
            </Text>
            {showTerminal && <Text size="S">{description}</Text>}
          </Spacing>
        </Box>
      </div>
    </div>
  );
};

StopItem.propTypes = propTypes;

export default StopItem;
