import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import 'styles/components/TripItinerary/TripItinerary';
import 'styles/components/purchase/RedeemOpenTicket';
import { Icon, Spacing, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import ResultItinerary from 'components/search/ResultItinerary';
import DateDetails from './DateDetails';
import ProviderDetails from '../ProviderDetails';
import SeatDetails from '../SeatDetails';
import openTicketETN from '../../../images/brands-unique/etn/openTicketETN.svg';

const propTypes = {
  title: PropTypes.string,
  trip: PropTypes.object.isRequired,
  itinerary: PropTypes.shape({
    transportType: PropTypes.string.isRequired,
    fragments: PropTypes.array.isRequired,
  }).isRequired,
  providerAmenities: PropTypes.array.isRequired,
  selectedSeats: PropTypes.array,
  showModal: PropTypes.func.isRequired,
  isOpenTicket: PropTypes.bool.isRequired,
  isRoundTrip: PropTypes.bool,
};

/**
 * TripItinerary component.
 *
 * Displays the trip itinerary details, including departure and arrival dates,
 * duration, and provider amenities. Also shows the selected seats and the
 * result itinerary.
 *
 * @param {Object} props - Component props
 * @param {string} props.title - Title of the trip
 * @param {Object} props.trip - Trip details
 * @param {Object} props.itinerary - Itinerary details
 * @param {Array} props.providerAmenities - Provider amenities
 * @param {Array} props.selectedSeats - Selected seats
 * @param {Function} props.showModal - Function to show modal
 * @param {Boolean} props.isOpenTicket - Whether the ticket is open
 * @param {Boolean} props.isRoundTrip - Flag indicating if it's a round trip
 */
const TripItinerary = ({
  title = 'trip',
  trip,
  itinerary,
  providerAmenities,
  selectedSeats,
  showModal,
  isOpenTicket,
  isRoundTrip,
}) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('purchase');
  const { departure, arrival, duration, tripSlug } = trip;
  const departureMoment = moment(departure);
  const arrivalMoment = moment(arrival);
  const { cityName: originCityName } = trip.origin;
  const { cityName: destinationCityName } = trip.destination;
  const showAmenitiesBeforeItinerary = features.AMENITIES_FIRST_AT_TRIP_DETAILS;

  return (
    <Spacing vertical size="L">
      {title && isRoundTrip && (
        <Text size="L" weight="bold" textAlign="center" color="accent">
          {t(`label.${title}`)}
        </Text>
      )}
      {!isOpenTicket ? (
        <Spacing vertical>
          <DateDetails
            departureMoment={departureMoment}
            arrivalMoment={arrivalMoment}
            duration={duration}
          />

          {showAmenitiesBeforeItinerary && (
            <>
              <ProviderDetails
                amenities={providerAmenities}
                showModal={showModal}
                isOpenTicket={isOpenTicket}
              />
              <SeatDetails selectedSeats={selectedSeats} />
            </>
          )}

          <ResultItinerary tripId={tripSlug} />
          <hr className="l-divider" />
          {!showAmenitiesBeforeItinerary && (
            <>
              <SeatDetails selectedSeats={selectedSeats} />
              <ProviderDetails
                amenities={providerAmenities}
                showModal={showModal}
                isOpenTicket={isOpenTicket}
              />
            </>
          )}
        </Spacing>
      ) : (
        <>
          <Spacing vertical size="S">
            <Spacing size="XS">
              <Icon type={openTicketETN} />
              <Text weight="semibold">
                {originCityName} - {destinationCityName}
              </Text>
            </Spacing>
            <Text weight="bold">{t('label.chosen_open_ticket')}</Text>
            <Text size="S">{t('open_ticket_instructions')}</Text>

            {itinerary.fragments.map((trip) => {
              return (
                trip.ticketUrl && (
                  <div className="rd-ticket">
                    <div className="tickets-succeed-label has-bg">
                      <a href={trip.ticketUrl} target="_blank">
                        {t('open_ticket_download')}
                      </a>
                    </div>
                  </div>
                )
              );
            })}
          </Spacing>

          <ProviderDetails
            amenities={providerAmenities}
            showModal={showModal}
            isOpenTicket={isOpenTicket}
          />
        </>
      )}
    </Spacing>
  );
};

TripItinerary.propTypes = propTypes;

export default TripItinerary;
