/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { useSelector } from 'react-redux';
import DepartureTripPicked from './DepartureTripPicked';
import DepartureTripPickedFlat from './DepartureTripPickedFlat';

// eslint-disable-next-line react/prop-types
const DepartureTripPickedFactory = (props) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const { FUNNEL_STYLE: funnelStyle, SHOW_DESTINATION_ANIMATION: showAnimation } = features;

  return funnelStyle === 'CLASSIC' ? (
    <DepartureTripPicked {...props} />
  ) : (
    <DepartureTripPickedFlat showAnimation={showAnimation} {...props} />
  );
};
export default DepartureTripPickedFactory;
