import { List, fromJS } from 'immutable';

/**
 * Transforms and filters specific keys within an Immutable.js data structure.
 * Replaces 'nationality' with 'isoCountryCode' for form passengers and filters out
 * any keys that are either ignored or not part of the allowed keys list.
 *
 * @param {Object} params - Function parameters.
 * @param {Immutable.Map} params.data - The data item to transform and filter.
 * @param {boolean} params.isFormPassenger - Indicates if the data is a form passenger object.
 * @param {Array<string>} params.ignoredKeys - Keys to exclude from the final output.
 * @param {Array<string>} params.allowedKeys - Keys to include in the final output.
 * @returns {Immutable.Map} - Transformed and filtered data item.
 */
export const transformAndFilterKeys = ({
  data,
  isFormPassenger = false,
  ignoredKeys = [],
  allowedKeys = [],
}) => {
  const transformedData = data
    .map((value) => (value === null ? undefined : value))
    .set('isoCountryCode', isFormPassenger ? data.get('nationality') : data.get('isoCountryCode'));

  return transformedData.filter(
    (_, key) => !ignoredKeys.includes(key) && allowedKeys.includes(key),
  );
};

/**
 * Compares two sets of passenger data by normalizing and filtering them to ensure consistency.
 * Utilizes `transformAndFilterKeys` to align each passenger and form passenger data item
 * based on the allowed keys, ignoring specific fields as defined in `ignoredKeys`.
 *
 * @param {Immutable.List} passengers - The current state of passengers data.
 * @param {Array<Object>} formPassengers - The form input of passengers data to compare.
 * @returns {boolean} - True if the normalized passengers data matches; otherwise, false.
 */
export const comparePassengers = (passengers, formPassengers) => {
  const ignoredKeys = ['name', 'seats', 'nationalityId', 'phoneCode', 'phone', 'phoneCountry'];
  const allowedKeys = List(passengers.first().keys());

  const normalize = (items, isFormPassenger) =>
    items.map((item) =>
      // eslint-disable-next-line prettier/prettier
      transformAndFilterKeys({ data: item, isFormPassenger, ignoredKeys, allowedKeys }));

  const normalizedPassengers = normalize(passengers, false);
  const normalizedFormPassengers = normalize(fromJS(formPassengers), true);


  return List(normalizedPassengers).equals(normalizedFormPassengers);
};
